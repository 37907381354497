import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Link } from "gatsby";





const NotFound   = ({ lan }) => {

    const {  logo,  } = useStaticQuery( graphql`
        query {
            picture: 
                file(relativePath: {eq: "blonde-girl.jpg"}) {
                    sharp: childImageSharp {
                        fluid( maxWidth: 1200 duotone: { highlight: "#222222", shadow: "#192550", opacity: 30 } ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            logo: 
                file(relativePath: {eq: "banner-logo.png"}) {
                    publicURL
                }
            whatsapp: 
                file(relativePath: {eq: "whatsapp.png"}) {
                    publicURL
                }
        }
    `);

    const basePath = lan === 'en' ? '/en' : '';
    return ( 
       <>
        
    <div style={{ textAlign: "center", padding: "50px" }}>
    <img src={logo.publicURL} alt="La Celestina" />
      <h1>¡Oops! {  lan === 'es' ? ' Página no encontrada. ' : ' Page Not found. ' }</h1>
      <p>{  lan === 'es' ? ' La página que estás buscando no existe. ' : ' The page you are looking for does not exist. ' }</p>
      <p>
        <Link to={`${basePath}/`}>{  lan === 'es' ? ' Volver a la página de inicio. ' : ' return to homepage. ' }</Link>
      </p>
    </div>
  
                  
                </>  
    );
}
 
export default NotFound;

//