// src/pages/404.js

import React from "react";
import NotFound from "../components/notfound";// Asegúrate de ajustar la importación según la estructura de tu proyecto

import Layout from '../components/layout';

const NotFoundPage = () => {
  
  
  
    return (
      <Layout lan={ 'en' }>
        <NotFound lan={ 'en' } />
      </Layout>
    );
  };

export default NotFoundPage;
